// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-containers-mobile-services-js": () => import("./../src/containers/mobileServices.js" /* webpackChunkName: "component---src-containers-mobile-services-js" */),
  "component---src-containers-web-app-services-js": () => import("./../src/containers/webAppServices.js" /* webpackChunkName: "component---src-containers-web-app-services-js" */),
  "component---src-containers-product-services-js": () => import("./../src/containers/productServices.js" /* webpackChunkName: "component---src-containers-product-services-js" */),
  "component---src-containers-it-services-js": () => import("./../src/containers/itServices.js" /* webpackChunkName: "component---src-containers-it-services-js" */),
  "component---src-containers-construction-industries-js": () => import("./../src/containers/constructionIndustries.js" /* webpackChunkName: "component---src-containers-construction-industries-js" */),
  "component---src-containers-healthcare-industries-js": () => import("./../src/containers/healthcareIndustries.js" /* webpackChunkName: "component---src-containers-healthcare-industries-js" */),
  "component---src-containers-digital-health-js": () => import("./../src/containers/digitalHealth.js" /* webpackChunkName: "component---src-containers-digital-health-js" */),
  "component---src-containers-spirometer-js": () => import("./../src/containers/spirometer.js" /* webpackChunkName: "component---src-containers-spirometer-js" */),
  "component---src-containers-event-app-js": () => import("./../src/containers/eventApp.js" /* webpackChunkName: "component---src-containers-event-app-js" */),
  "component---src-containers-erp-js": () => import("./../src/containers/erp.js" /* webpackChunkName: "component---src-containers-erp-js" */),
  "component---src-containers-contact-company-js": () => import("./../src/containers/contactCompany.js" /* webpackChunkName: "component---src-containers-contact-company-js" */),
  "component---src-pages-clients-js": () => import("./../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-company-js": () => import("./../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contactus-js": () => import("./../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-solutions-js": () => import("./../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */)
}

